<template>
  <div class="register-background">
    <div class="coupons-con">
      <div class="header-box">
        <div class="header-box-person">{{ invitePerson }}邀请你</div>
        <div class="header-box-rent">一起海外租房啦！</div>
        <div class="header-box-bonus">-租好房 赢奖励-</div>
      </div>
      <div class="container">
        <!-- 优惠券 -->
        <div class="scrool">
          <div
            class="coupons-con-img"
            v-for="(item, index) in couponsList"
            :key="index"
          >
            <div class="coupons-flex-left">
              <div class="coupons-title">{{ item.name }}</div>
              <div class="coupons-time">新用户可用</div>
              <div class="coupons-time">
                有效期:{{ timeFormat(item.couponDateStart) }}-{{
                  timeFormat(item.couponDateEnd)
                }}
              </div>
            </div>
            <div class="coupons-flex-right">注册即送</div>
          </div>
        </div>
        <div class="invite-desc" v-if="registerStatus">
          ····欢迎使用以下邀请方式····
          <!-- <img style="width:200px;height:200px" :src="rawQrCode" alt=""> -->
        </div>
        <div class="invite-way" v-if="registerStatus">
          <div class="wx-share invite-item" @click="wxShare">
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/app/wx.png"
            ></van-image>
            微信
          </div>
          <div class="wx-friend invite-item" @click="shareToCircleButton">
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/app/wx_moment.png"
            ></van-image>
            朋友圈
          </div>
          <div class="poster invite-item" @click="generatePosterButton">
            <van-image
              width="36"
              height="36"
              src="https://img1.clozhome.com/app/photo.png"
            ></van-image>
            生成海报
          </div>
        </div>
        <div class="phone-lable" v-if="!registerStatus">
          <div class="lable-number" @click="isShowCode = true">
            +{{ codeData }}
            <van-image
              class="xiala-img"
              width="12"
              height="12"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/xiala.png"
            />
          </div>
          <van-field
            v-model="phoneNumber"
            type="tel"
            placeholder="请输入手机号"
          />
        </div>
        <div class="phone-lables" v-if="!registerStatus">
          <div class="lable-number">
            <van-image
              class="password"
              width="20"
              height="20"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/password.png"
            />
          </div>
          <van-field
            v-model="codeValue"
            center
            clearable
            placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button
                class="code-btn"
                size="small"
                type="primary"
                :disabled="isSend ? true : false"
                @click="onSendCode"
                >{{ isSend ? times + "s后重发" : "发送验证码" }}</van-button
              >
            </template>
          </van-field>
        </div>
        <!-- <div class="poster">
          下面是canvas图片
          <van-image width="375" height="600" :src="image" mode="scaleToFill" />
        </div> -->
        <div class="phone-button" @click="register" v-if="!registerStatus">
          立即获取
        </div>
      </div>

      <!-- 弹框 -->
      <van-popup v-model="isShowCode" position="bottom">
        <van-picker
          show-toolbar
          :columns="codeActions"
          @cancel="isShowCode = false"
          @confirm="onConfirmCode"
        />
      </van-popup>
    </div>
    <div class="canvas-box">
      <div id="canvas" class="canvas">
        <van-image
          class="canavs-bg"
          src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/bg.png"
        >
        </van-image>
        <div class="canvas-content">
          <div class="canvas-header">
            <div class="canvas-person">{{ invitePerson }}</div>
            <div class="canvas-invite">邀请你</div>
          </div>
          <div class="canvas-rent">一起海外租房啦！</div>
          <div class="canvas-bonus">
            <div class="canvas-bonus-box">好房易租 各领200元奖励</div>
          </div>
          <div class="canvas-text">
            <div>海外租房新选择，走遍世界都是家！一起来选房吧！</div>
            <div>#英国#美国#加拿大#澳洲#中国香港#新加坡#</div>
          </div>
          <div class="canvas-code">
            <img class="code-image" :src="rawQrCode" alt="" />
          </div>
          <div class="canvas-scan">扫一扫 赢奖励</div>
        </div>
      </div>
    </div>
    <van-overlay lock-scroll :show="showAuth">
      <div class="wrapper">
        <div class="block">
          <!-- 标题 -->
          <div class="auth-header-box">
            <div class="auth-header">请按照下方图片填写答案</div>
            <van-image
              @click="closeAuth()"
              class="close-auth"
              width="24"
              height="24"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/close.png"
            />
          </div>
          <!-- 标题... -->
          <!-- 图片 -->
          <div class="image-box" @click="updateUrl()">
            <van-image
              class="inline"
              :src="authUrl"
              width="310"
              height="100"
              mode="scallToFill"
            >
            </van-image>
          </div>
          <!-- 图片... -->
          <!-- 输入框 -->
          <div class="login-box">
            <van-image
              class="inline"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/password.png"
              width="20"
              height="20"
            >
            </van-image>
            <div class="" style="width: 10px; height: 1px"></div>
            <div class="code-btn">
              <input
                v-model="codeResult"
                type="text"
                :placeholder-style="holderStyle"
                placeholder="请输入验证码"
              />
            </div>
          </div>
          <div
            class="login-box"
            :class="isComplete ? 'orange-bgc' : ''"
            @click="complete()"
          >
            完成
          </div>
          <!-- 输入框.. -->
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import {
  getPhoneCode,
  getPhoneNumber,
  codeNickName,
  posterCode,
  uploadBase64,
} from "@/api/service";
import { getNewUserCoupon, phoneCodeRegister } from "@/api/user";

export default {
  name: "Register",
  data() {
    return {
      showAuth: false,
      holderStyle: "color:#999999; font-size:16px;background-color:#f2f2f2",
      codeResult: null, //图片验证码结果
      value: "",
      value2: "",
      showPopover: false,
      couponsList: [],
      codeActions: [],
      //区号
      codeData: undefined,
      isShowCode: false,
      //手机号
      phoneNumber: undefined,
      isSend: false,
      //验证码
      codeValue: undefined,
      times: 60,
      //推广码
      inviteCode: "",
      invitePerson: "",
      invitePosterPath: "",
      image: "",
      // 判断是发出邀请,还是接收邀请进来的
      registerStatus: true,
      rawQrCode: "",
      QrCode: "",
      apiUrl: process.env.VUE_APP_BASE_API,
      timeStamp: "",
    };
  },
  created() {
    this.inviteCode = this.$route.query.inviteCode;
    this.getNickName(this.inviteCode);
    this.registerStatus =
      this.$route.query.registerStatus === "old" ? true : false;
    if (this.registerStatus) {
      document.title = "邀请好友";
    }
    this.onload();
    this.timeStamp = new Date().getTime();
  },
  computed: {
    authUrl() {
      return `${this.apiUrl}captcha/verification?phone=${this.phoneNumber}&timeStamp=${this.timeStamp}`;
    },
    isComplete() {
      let flag = false;
      if (!this.codeResult) {
        flag = false;
      } else {
        flag = true;
      }
      return flag;
    },
  },
  methods: {
    //关闭图片验证码弹框
    closeAuth() {
      this.showAuth = false;
      console.log("this.showAuth结果===>", this.showAuth);
    },
    updateUrl() {
      this.timeStamp = new Date().getTime();
    },
    // 微信分享
    async wxShare() {
      await this.generatePosterCanvas();
      shareMiniProgramToFriends.postMessage(
        JSON.stringify({
          thumnail: this.image,
          title: "邀请好友租房 共赢现金奖励",
          miniPath: `/myPages/menu-list/invitation/register/register?inviteCode=${this.inviteCode}`,
        })
      );
    },
    // 微信分享...
    // 朋友圈分享
    async shareToCircleButton() {
      await this.generatePosterCanvas();
      sharePosterToCircle.postMessage(
        JSON.stringify({
          thumnail: this.image,
          title: "邀请好友租房 共赢现金奖励",
          posterUrl: this.image,
        })
      );
    },
    // 朋友圈分享...
    // 点击生成海报的按钮
    async generatePosterButton() {
      await this.generatePosterCanvas();
      console.log("canvas结果===>", this.image);
      enterGeneratePosterPage.postMessage(
        JSON.stringify({
          posterUrl: this.image,
        })
      );
    },
    // 点击生成海报的按钮...
    // 根据邀请码获取邀请人昵称
    getNickName(inviteCode) {
      codeNickName({ inviteCode: inviteCode }).then(({ data }) => {
        console.log("根据邀请码获取邀请人昵称==>", data);
        this.invitePerson = data.nickName;
        this.invitePosterPath = data.invitePosterPath;
      });
    },
    // 获取小程序码
    async getWxCode(qrType, params) {
      const CodeObj = await posterCode(qrType, params);
      // console.log("this.rawQrCode==base64结果===>", CodeObj.data.base64);
      // imageURIScheme = new BASE64Encoder().encode(bytes).trim();
      // console.log('this.rawQrCode结果===>',CodeObj.data)
      return (this.rawQrCode = CodeObj.data.url);
    },
    // 获取小程序码...

    // 生成海报
    async generatePosterCanvas() {
      if (this.invitePosterPath) {
        this.image = this.invitePosterPath;
        return;
      }
      window.scrollTo(0, 0);
      var that = this;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      await this.getWxCode("2", this.inviteCode);
      //两个参数：所需要截图的元素id，截图后要执行的函数， canvas为截图后返回的最后一个canvas
      let canvasRes = await html2canvas(document.getElementById("canvas"), {
        allowTaint: false, //允许污染
        taintTest: true, //在渲染前测试图片(没整明白有啥用)
        useCORS: true, //使用跨域(当allowTaint为true时这段代码没什么用,下面解释)
        // background: "#fff",
        scale: 2, // 处理模糊问题
        dpi: 300, // 处理模糊问题
      });
      const image = canvasRes.toDataURL();
      const tempStr = image.split(",");
      const data = new FormData();
      data.append("base64Str", tempStr[1]);
      // console.log("tempStr结果===>", tempStr);
      const imageUrl = await uploadBase64(data);
      that.image = imageUrl.data;
      // console.log('imageUrl结果===>',imageUrl)
      that.$toast.clear();
      // that.$successToast("生成海报成功");
    },
    // 生成海报...
    onload() {
      getPhoneCode().then((res) => {
        this.codeData = res.data[0].phoneCode;
        let data = [];
        res.data.forEach((item) => {
          data.push({
            text: item.countryName + "+" + "(" + item.phoneCode + ")",
            code: item.phoneCode,
          });
        });
        this.codeActions = data;
      });

      //获取新人优惠券
      getNewUserCoupon().then((res) => {
        this.couponsList = res.data;
        console.info(res);
      });
    },
    //获取区域号
    onConfirmCode(value) {
      this.codeData = value.code;
      this.isShowCode = false;
    },
    //短信发送
    complete() {
      // this.onSendCode()
      getPhoneNumber({
        phoneNumber: this.phoneNumber,
        code: this.codeData,
        securityCheckVal: parseInt(this.codeResult),
        isRegister: 1,
      }).then((res) => {
        if (res.code === 19) {
          this.showAuth = true;
          return;
        }
        if (res.code === 20) {
          this.$failToast("图片验证码错误,请重新输入");
          return;
        }
        if (res.code == 1003) {
          this.$successToast("手机号已注册，直接下载吧~");
          //手机号已经存在
          setTimeout(() => {
            this.$router.push({
              name: "RegisterSuccess",
            });
          }, 1500);
        } else {
          this.showAuth = false;
          this.$successToast("发送成功");
          this.isSend = true;
          clearInterval(this.timer);
        }
      });
    },
    onSendCode() {
      if (!this.phoneNumber) {
        this.$failToast("请输入手机号");
        return;
      }
      this.isSend = true;
      this.timer = setInterval(() => {
        this.times--;
        if (this.times === 0) {
          this.isSend = false;
          this.times = 60;
          clearInterval(this.timer);
        }
      }, 1000);
      getPhoneNumber({
        phoneNumber: this.phoneNumber,
        code: this.codeData,
        securityCheckVal: parseInt(this.codeResult),
        isRegister: 1,
      }).then((res) => {
        console.info(res);
        if (res.code === 19) {
          this.showAuth = true;
          return;
        }
        if (res.code === 20) {
          this.$failToast("图片验证码错误,请重新输入");
          return;
        }
        if (res.code == 1003) {
          this.$successToast("手机号已注册，直接下载吧~");
          //手机号已经存在
          setTimeout(() => {
            this.$router.push({
              name: "RegisterSuccess",
            });
          }, 1500);
        } else {
          this.$successToast("发送成功");
          this.isSend = true;
        }
      });
    },
    timeFormat(timeStr) {
      return timeStr ? timeStr.replace(/-/g, ".") : "";
    },
    //注册
    register() {
      if (!this.phoneNumber) {
        this.$failToast("请输入手机号");
        return;
      }
      if (!this.codeValue) {
        this.$failToast("请输入验证码");
        return;
      }
      phoneCodeRegister({
        phone: this.phoneNumber,
        phoneCode: this.codeData,
        captchaCode: this.codeValue,
        inviteCode: this.inviteCode,
      }).then(() => {
        this.$successToast("注册成功");
        this.$router.push({
          name: "RegisterSuccess",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flexone {
  display: flex;
  align-items: center;
  justify-content: center;
}
// 验证码弹框
.wrapper {
  @include flexone;
  height: 100%;
}

.block {
  width: 325px;
  height: 330px;
  background-color: #fff;
  padding: 20px 10px;
  .auth-header-box {
    @include flexone;
    justify-content: space-around;
    .auth-header {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 700;
      color: #333333;
    }
  }
  .image-box {
    margin-top: 20px;
    @include flexone;
    height: 100px;
  }
  .login-box {
    font-size: 18px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
    // background-color: #999;
    border: 1px solid transparent;
    border-radius: 22px;
    overflow: hidden;
    background-color: #f2f2f2;
    color: #fff;

    .code-btn {
      width: 225px;
      color: #000000;
      input {
        border: none;
        background-color: #f2f2f2;
      }
    }
  }

  .orange-bgc {
    background-color: #f67f58;
    border: 1px solid #f67f58;
  }
}
// 验证码弹框

.canvas-box {
  position: fixed;
  top: 0;
  left: 300%;
  // z-index: -1;
  // left: -9300px;
  .canvas {
    position: relative;
    overflow: hidden;
    width: 375px;
    height: 600px;

    .canavs-bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    .canvas-content {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 30px;
      position: relative;
      z-index: 2;
      .canvas-header {
        display: flex;
        align-items: center;
        .canvas-person {
          background: #fefefe;
          border-radius: 4px;
          font-size: 21px;
          font-weight: 800;
          color: #ef584a;
        }
        .canvas-invite {
          margin-left: 5px;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          text-shadow: -3px 3px 4px #c33222;
        }
      }
      .canvas-rent {
        margin: 30px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 36px;
        font-weight: 400;
        color: #ffffff;
        text-shadow: -3px 3px 4px #c33222;
      }
      .canvas-bonus {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .canvas-bonus-box {
          // width: 202px;
          height: 26px;
          background: #ffd200;
          border-radius: 8px;
          font-size: 16px;
          font-weight: bold;
          color: #ee574a;
          // width: 100%;
          padding: 5px 13px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .canvas-text {
        margin-top: 200px;
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
      .canvas-code {
        width: 100%;
        height: 106px;
        display: flex;
        justify-content: center;
        .code-image {
          width: 106px;
          height: 106px;
        }
      }
      .canvas-scan {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.register-background {
  // overflow-y: scroll;
  width: 375px;
  min-height: 752px;
  background: url("https://img1.clozhome.com/new-clozhome/app/invite_bg.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 40px;
}

.coupons-con {
  background: url("https://img1.clozhome.com/new-clozhome/app/invite_img.png")
    no-repeat center;
  background-size: 95% 100%;
  padding: 0px 18px;
  > .container {
    padding: 180px 20px 100px 20px;
  }
}

.header-box {
  // padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header-box-person {
    font-size: 17px;
    font-weight: 500;
    color: #ff5a27;
  }

  .header-box-rent {
    font-size: 37px;
    font-weight: bold;
    color: #ffffff;
    line-height: 72px;
    opacity: 0.97;
    text-shadow: 0px 10px 5px rgba(190, 102, 34, 0.2);
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 44.7998046875%,
      rgba(255, 255, 255, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .header-box-bonus {
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 10px 6px rgba(190, 102, 34, 0.2);

    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 44.7998046875%,
      rgba(255, 255, 255, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.scrool {
  height: 180px;
  overflow: auto;
  &::-webkit-scrollbar {
    /*隐藏滚轮*/
    width: 0 !important;
    display: none;
  }
  -webkit-overflow-scrolling: touch;

}
.coupons-con-img {
  margin-top: 10px;
  height: 80px;
  background: url("https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/coupons.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}
.coupons-con-img:first-child {
  margin-top: 0px;
}
.coupons-flex-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0 10px 15px;
}
.coupons-title {
  font-size: 12px;
  color: #e01212;
}
.coupons-time {
  font-size: 10px;
  color: #e01212;
}
.coupons-flex-right {
  display: flex;
  align-items: center;
  padding-right: 20px;
  font-size: 15px;
  color: #e01212;
}
.phone-lable,
.phone-lables {
  display: flex;
  background: #fff;
  margin-top: 12px;
}
.lable-number {
  font-size: 18px;
  position: relative;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: flex-end;
}
.xiala-img {
  position: absolute;
  bottom: 10px;
  right: 0;
}
.phone-lables /deep/.van-cell {
  padding-top: 7px;
  padding-bottom: 7px;
}
.phone-button {
  height: 65px;
  width: 96%;
  background: url("https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/button.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 10px;
  font-size: 20px;
  margin-left: 2%;
  color: #ff5926;
  line-height: 45px;
  text-align: center;
  letter-spacing: 1px;
}
.bounced-click {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.code-btn {
  width: 90px;
}

.invite-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  padding: 15px 0;
}

.invite-way {
  width: 296px;
  height: 72px;
  background: #fefefe;
  opacity: 0.8;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
}

.invite-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.wx-share {
  position: relative;
}
</style>
